import BR from 'primitives/Layout/BR';
import CardContainer from './primitives/CardContainer';
import checkAutoplay from 'web-player/autoplay';
import NavLink from 'primitives/Typography/Links/NavLink';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import PodcastCard from 'views/Podcast/PodcastCard';
import RichResults from 'components/RichResults';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import ViewTransition from 'primitives/ViewTransition';
import { Episode } from 'state/Podcast/types';
import { get } from 'lodash-es';
import { getPodcastEpisodeUrl } from 'state/Podcast/helpers';
import { PlayerState } from 'state/Playback/types';
import { next as playNext } from 'components/Player/PlayerActions/shims';
import { STATION_TYPE, StationType } from 'constants/stationTypes';
import { useEffect } from 'react';
import { ViewName } from 'views/PageBody';
import { WidgetDimensions } from 'constants/widgetDimensions';
import type { Props as PodcastSeriesProps } from '../PodcastProfile/PodcastProfile';

export type Props = {
  currentPodcast: PodcastSeriesProps;
  currentlyPlaying: {
    mediaId: number;
    playedFrom: string;
    seedId: number | string;
    stationTrackId: number;
    stationType: StationType;
  };
  episode: Episode;
  episodeId: number;
  episodeTitle?: string;
  getPodcastEpisodeWithAuth: (seedId: number) => void;
  playingState: PlayerState;
  position?: number;
  seedId: number;
  showPodcastTranscriptions?: boolean;
  slug: string;
  stationLoaded: boolean;
  title?: string;
  widgetDimensions?: WidgetDimensions;
  onClick?: () => void;
};

function PodcastEpisodeCard({
  currentPodcast,
  episode,
  episodeId,
  currentlyPlaying,
  getPodcastEpisodeWithAuth,
  showPodcastTranscriptions,
  playingState,
  position,
  seedId,
  slug,
  stationLoaded,
  onClick,
}: Props) {
  const translate = useTranslate();
  const { transcriptionAvailable } = episode;

  useEffect(() => {
    if (stationLoaded) {
      checkAutoplay({
        episodeId,
        seedId: get(episode, 'podcastId'),
        seedType: STATION_TYPE.PODCAST,
      });
    }

    if (episodeId) {
      getPodcastEpisodeWithAuth(episodeId);
    }
  }, [episodeId, stationLoaded]);

  return (
    <>
      <CardContainer>
        <ViewTransition>
          <PodcastCard
            alwaysOpen
            currentlyPlaying={currentlyPlaying}
            episodeDuration={episode?.duration}
            isNewEpisode={episode?.new}
            onClick={onClick}
            pageName="podcast_episode_profile"
            playingState={playingState}
            playNext={playNext}
            position={position}
            showPodcastTranscriptions={
              transcriptionAvailable && showPodcastTranscriptions
            }
            {...episode}
          />
        </ViewTransition>

        <BR />

        <NavLink to={`/podcast/${slug}-${seedId}`}>
          <OutlinedButton>{translate('All Episodes')}</OutlinedButton>
        </NavLink>
      </CardContainer>
      <RichResults
        meta={{
          description: episode?.description,
          name: episode?.title,
          url: getPodcastEpisodeUrl(
            episode?.podcastId,
            episode?.podcastSlug,
            episode?.id,
            episode?.title,
          ),
          image: episode?.imageUrl ?? undefined,
          associatedMedia: episode?.mediaUrl,
          duration: episode?.duration,
          partOfSeries: {
            name: currentPodcast.title,
            description: currentPodcast.description,
            hosts: currentPodcast.hosts,
            image: currentPodcast.imgUrl,
            url: currentPodcast.pathname ?? undefined,
          },
        }}
        type={ViewName.PodcastEpisode}
      />
    </>
  );
}

export default PodcastEpisodeCard;
