import styled from '@emotion/styled';

const CardContainer = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  margin: '1.5rem 0 2.4rem',

  '> div:first-of-type': {
    width: '100%',
  },
});

export default CardContainer;
